<template lang="html">
  <div class="">
    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Valor de vivienda</span>
        <div class="inputwrapper" data-required="MXM">  
        <input  id="ValorVivienda"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:11', 'min:6'"  v-model="valores.valor" name="vv" @change="updateValores(); formatea(); calculaPrcentajeCredito()" v-on:keyup="validavalor()" v-on:keypress="isNumber($event)" v-on:blur="validaMontos()"/>
        <span class="text-danger span-placeholder" v-show="errors.has('vv')">Mínimo son 6 digitos</span>        
        </div>        
        <span id="labelValorVivienda" class="text-danger span-placeholder" style="display: none; color: red; ">Error: la suma de los Montos de cotización es mayor al valor de vivienda</span>
        <span 
          class="text-danger span-placeholder d-block" 
          v-if="isDecimalV">Se recomienda trabajar números enteros.</span>
      </div>
      <div class="col-md-4" v-if="programa == 9 || programa == 4">
        <span class="span-placeholder">Monto de crédito solicitado</span>
        <div class="alinear">  
        <input id="montoCredito"  class="inputDataText" placeholder="$X,XXX,XXX.XX"  v-validate="'max:11'"  v-model="valores.montoCredito" name="vv" @change="updateValores(); formateaCredito(); calculaPrcentajeCredito()" v-on:keyup="validaCredito()" v-on:keypress="isNumber($event)" v-on:blur="validaMontos()"/>
        <!--<span class="text-danger span-placeholder" v-show="errors.has('vv')">Máximo son 10 digitos</span>-->
        <div class="porcentaje-dos">{{valores.porcentajeCredito}}</div>        
        </div>
        <span id="labelMontoCredito" class="text-danger span-placeholder" style="display: none; color: red; ">Error: El monto de crédito es mayor al valor de la vivienda</span>
        <span 
          class="text-danger span-placeholder d-block" 
          v-if="isDecimalM">Se recomienda trabajar números enteros.</span>
      </div>
      <div class="col-md-4" v-if="Destino != 19">
        <span class="span-placeholder">Plazos a cotizar</span>
        <v-select  v-model="valores.plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
    </div>
    <div class="row w-full">
      <div class="col-md-4" v-if="Destino != 19">
        <span class="span-placeholder">Esquemas de pago</span>
        <v-select v-model="valores.esquemasACotizar" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
      <!--<div class="vx-col lg:w-1/4 sm:w-full">
        <span class="span-placeholder">Gastos notariales</span>
        <select name="gastosNotariales" id="gastosNotariales" v-model="valores.gastosNotarial" class="v-select vs--single  vs-input--input" @change="updateValores()">
              <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
        </select>
      </div>-->
      <div class="col-md-4" v-if="programa==4 || programa == 6">
        <span class="span-placeholder"> Crédito infonavit</span>
        <div class="alinear">
        <input id="CreditoInfo" class="vs-input"  v-validate="'required'" v-model="valores.creditoInf" name="ci" @change="updateValores(); calculaPrcentajeCredito(); formateaporcentageCredito(); " v-on:keypress="isNumber($event)" v-on:blur="validaMontos()"/>
        <div class="porcentaje-dos">{{valores.porcentajeCreditoInfonavit}}</div>
        </div>
        <span class="text-danger span-placeholder" v-show="errors.has('ci')">Campo requerido</span>
        <span id="labelCreditoInfo" class="text-danger span-placeholder" style="display: none; color: red; ">Error: El crédito Infonavit es mayor al valor de la vivienda</span>
        <span 
            class="text-danger span-placeholder d-block" 
            v-if="isDecimalCIN">Se recomienda trabajar números enteros.</span>
      </div>

      <div class="col-md-4"> 
        <span class="span-placeholder">Saldo de subcuenta (SSV)</span> 
        <div class="alinear">
        <input id="ssv" class="vs-input"  v-validate="'required, max:13'" v-model="valores.ssv" name="ssv"  @change="updateValores(); calculaPrcentajeCredito(); formateaporcentage(); " v-on:keyup="validavalorPorcen()" v-on:keypress="isNumber($event)" v-on:blur="validaMontos()"/>
        <div class="porcentaje-dos">{{valores.porcentajeSaldoSubcuenta}}</div>
        </div>
        <span class="text-danger span-placeholder" v-show="errors.has('ssv')">Campo requerido</span>
        <span id="labelSsv" class="text-danger span-placeholder" style="display: none; color: red; ">Error: El saldo de subcuenta es mayor al valor de la vivienda</span>
        <span 
            class="text-danger span-placeholder d-block" 
            v-if="isDecimalSSV">Se recomienda trabajar números enteros.</span>
      </div>
    </div>

    <input class="col-md-1" hidden   v-model="otro" name="otro" @change="updateValores()" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
   props: {
    otro: {
      type: Number,
      required: false
    },
    programa:{
      type: Number,
      required: false
    },
    Destino:{
      type: Number,
      required: false
    }
   },  
  data(){
		return{
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '25 Años',  value: '25' },
        { label: '24 Años',  value: '24' },
        { label: '23 Años',  value: '23' },
        { label: '22 Años',  value: '22' },
        { label: '21 Años',  value: '21' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '15 Años',  value: '15' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '10 Años',  value: '10' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '5 Años',  value: '5' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      valores:{
        destino:11,
        valor:'',
        creditoInf:'',
        ssv:'',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '20 Años',  value: '20' }],
        bancos:[],
        gastosNotarial:6,
        otro:0,
        montoCredito:0,
        porcentajeCreditoInfonavit:0,
        porcentajeSaldoSubcuenta:0
      },
      isDecimalV: false,
      isDecimalM: false,
      isDecimalSSV:false,
      isDecimalCIN:false
    }
  },
  mounted(){
    console.log("Destino " + this.Destino)
    this.id=this.$route.params.id
    this.getApikey()
    if(this.id)this.dameCaso()
    if(this.$route.path == '/nuevaCotizacion' && localStorage.getItem('caso')){
      this.id=localStorage.getItem('caso')
      this.dameCaso()
    }
    this.updateValores()
  },
  watch: {
    /*valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },*/
    creditoInf: function(newValue) {
      this.valores.creditoInf= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    ssv: function(newValue) {
      this.valores.ssv= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    otro:function(){
      if(this.otro > 0){
        this.updateValores()
      }
    },
    'valores.valor': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let valceros = newValue.split('.');
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue) && valceros[1] > 0) {
        // console.log( 'Tiene puntoV' );
        this.isDecimalV = true;
      } else {
        this.isDecimalV = false;
      }
    },
    'valores.montoCredito': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let valceros = newValue.split('.');
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue) && valceros[1] > 0) {
        // console.log( 'Tiene puntoM' );
        this.isDecimalM = true;
      } else {
        this.isDecimalM = false;
      }
    },
    'valores.ssv': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let valceros = newValue.split('.');
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue) && valceros[1] > 0) {
        // console.log( 'Tiene puntoM' );
        this.isDecimalSSV = true;
      } else {
        this.isDecimalSSV = false;
      }
    },
    'valores.creditoInf': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let valceros = newValue.split('.');
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue) && valceros[1] > 0) {
        // console.log( 'Tiene puntoM' );
        this.isDecimalCIN = true;
      } else {
        this.isDecimalCIN = false;
      }
    }
  },
  computed:{
    /*valor(){
      return this.valores.valor
    },
    creditoInf(){
      return this.valores.creditoInf
    },
    ssv(){
      return this.valores.ssv
    },*/
  },
  methods:{
    formateaCredito(){
      this.valores.montoCredito = this.valores.montoCredito.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.montoCredito =='' || this.valores.montoCredito == 0 || this.valores.montoCredito.length > 13){
        this.valores.montoCredito=700000
      }
      
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.montoCredito= this.valores.montoCredito.toString().replace(exp,rep);
    },
    validavalor() {
      this.valores.valor=this.valores.valor.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.valor);
      if(matches===true){
      this.valores.valor= this.valores.valor
      }else{
        this.valores.valor=this.valores.valor.replace(/.$/, '')
      }
    },
    validavalorPorcen() {
      this.valores.ssv=this.valores.ssv.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.ssv);
      if(matches===true){
      this.valores.ssv= this.valores.ssv
      }else{
        this.valores.ssv=this.valores.ssv.replace(/.$/, '')
      }
    },
    formatea(){

      this.valores.valor = this.valores.valor.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

       if(this.valores.valor=='' || this.valores.valor==0){
        this.valores.valor = 100000
      }     
      if(this.valores.valor.length < 6 || this.valores.valor.length > 13){
        this.valores.valor = 100000 
      }


      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.valor= this.valores.valor.toString().replace(exp,rep);
    },
    formateaporcentage(){

      this.valores.ssv = this.valores.ssv.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.ssv=='' || this.valores.ssv.length > 11){
        this.valores.ssv=0
      }
    
      
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.ssv= this.valores.ssv.toString().replace(exp,rep);
    },
    formateaporcentageCredito(){
      this.valores.creditoInf = this.valores.creditoInf.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.creditoInf =='' || this.valores.creditoInf.length > 11){
        this.valores.creditoInf=0
      }
    
      
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.ssv= this.valores.ssv.toString().replace(exp,rep);
    },
    calculaPrcentajeCredito(){

      this.valores.porcentajeCredito= (this.valores.montoCredito.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100
      this.valores.porcentajeCredito=this.valores.porcentajeCredito.toFixed(2)+'%'
      if(this.valores.creditoInf.length < 11){
          this.valores.porcentajeCreditoInfonavit= (this.valores.creditoInf.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100
          this.valores.porcentajeCreditoInfonavit=this.valores.porcentajeCreditoInfonavit.toFixed(2)+'%'
      }else{
          this.valores.porcentajeCreditoInfonavit = 0;
      }
      if(this.valores.ssv.length < 11){
        this.valores.porcentajeSaldoSubcuenta= (this.valores.ssv.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100
        this.valores.porcentajeSaldoSubcuenta=this.valores.porcentajeSaldoSubcuenta.toFixed(2)+'%'
      }else{
        this.valores.porcentajeSaldoSubcuenta = 0;
      }
    },
    validaCredito() {
      this.valores.montoCredito=this.valores.montoCredito.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.montoCredito);
      if(matches===true){
      this.valores.montoCredito= this.valores.montoCredito
      }else{
        this.valores.montoCredito=this.valores.montoCredito.replace(/.$/, '')
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateValores(){
      this.valores.otro=this.otro
      this.$emit('updateValores',this.valores)
    },
    dameCaso(){
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            // this.valores.destino=cotizacion.Destino
            this.valores.plazosACotizar=[]
            this.valores.esquemasACotizar=[]
            this.valores.valor=(cotizacion.ValorInmueble).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            if(cotizacion.MontoSolicitado){
              this.valores.credito=(cotizacion.MontoSolicitado).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
           for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.valores.plazosACotizar.push(cotizacion.Plazos[i]+' Años')
            }
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.valores.esquemasACotizar.push({ label: 'Pago Fijo',  value: '2' })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.valores.esquemasACotizar.push({ label: 'Pago creciente',  value: '1' })
              }
            }
            if(cotizacion.MontoCredito){
             this.valores.montoCredito=(cotizacion.MontoCredito).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(cotizacion.SubCuentaVivienda){
              this.valores.ssv=(cotizacion.SubCuentaVivienda).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            if(cotizacion.CreditoInfonavit){
            this.valores.creditoInf=(cotizacion.CreditoInfonavit).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            this.updateValores()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    buscaBancos(){
      this.valores.otro=this.otro 
      this.$emit('buscaBancos',this.valores)
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
    validaMontos(){      

      var ValorDeVivienda = this.valores.valor;
      var MontoCredito = this.valores.montoCredito
      var CreditoInfonavit = this.valores.creditoInf
      var ssv = this.valores.ssv;

      if (ValorDeVivienda == '') {
        ValorDeVivienda = "0,0";
      } if (MontoCredito == ''){ 
        MontoCredito = "0,0";
      } if(CreditoInfonavit == ''){
        CreditoInfonavit = "0,0";
      } if(ssv == ''){
        ssv = "0,0";
      }

      document.getElementById("ValorVivienda").style.border = "1px solid #CCCCCC"
      document.getElementById("montoCredito").style.border = "1px solid #CCCCCC"
      document.getElementById("CreditoInfo").style.border = "1px solid #CCCCCC"
      document.getElementById("ssv").style.border = "1px solid #CCCCCC"
      document.getElementById("labelValorVivienda").style.display = 'none';
      document.getElementById("labelMontoCredito").style.display = 'none';
      document.getElementById("labelCreditoInfo").style.display = 'none';
      document.getElementById("labelSsv").style.display = 'none';

     
      ValorDeVivienda = parseFloat(ValorDeVivienda.replace(/,/g,""));      
      MontoCredito = parseFloat(MontoCredito.replace(/,/g,""));      
      CreditoInfonavit = parseFloat(CreditoInfonavit.replace(/,/g,""));      
      ssv = parseFloat(ssv.replace(/,/g,""));

      var sumaDeMontos = (MontoCredito + CreditoInfonavit + ssv) ;

     
      if (MontoCredito > ValorDeVivienda) {
          document.getElementById("montoCredito").style.border = "1px solid #FF0000"
          document.getElementById("labelMontoCredito").style.display = 'block';
      }else if(CreditoInfonavit > ValorDeVivienda){
        document.getElementById("CreditoInfo").style.border = "1px solid #FF0000"
        document.getElementById("labelCreditoInfo").style.display = 'block';
      }else if(ssv > ValorDeVivienda){
        document.getElementById("ssv").style.border = "1px solid #FF0000"
        document.getElementById("labelSsv").style.display = 'block';
      }
      else if (sumaDeMontos > ValorDeVivienda) {
        document.getElementById("ValorVivienda").style.border = "1px solid #FF0000"
        document.getElementById("montoCredito").style.border = "1px solid #FF0000"
        document.getElementById("CreditoInfo").style.border = "1px solid #FF0000"
        document.getElementById("ssv").style.border = "1px solid #FF0000"
        document.getElementById("labelValorVivienda").style.display = 'block';
        document.getElementById("labelMontoCredito").style.display = 'none';
        document.getElementById("labelCreditoInfo").style.display = 'none';
        document.getElementById("labelSsv").style.display = 'none';
      }else{
        document.getElementById("ValorVivienda").style.border = "1px solid #CCCCCC"
        document.getElementById("montoCredito").style.border = "1px solid #CCCCCC"
        document.getElementById("CreditoInfo").style.border = "1px solid #CCCCCC"
        document.getElementById("ssv").style.border = "1px solid #CCCCCC"
        document.getElementById("labelValorVivienda").style.display = 'none';
        document.getElementById("labelMontoCredito").style.display = 'none';
        document.getElementById("labelCreditoInfo").style.display = 'none';
        document.getElementById("labelSsv").style.display = 'none';
      }      
    }
  }
}
</script>

<style lang="css" scoped>

/*#ValorVivienda{
  border-color: aqua;
}*/
</style>
