<template lang="html">
  <div class="row w-full">
    <div class="col-md-4">
      <span class="span-placeholder">Valor de terreno</span>
      <div class="inputwrapper" data-required="MXM">
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valor" name="username"/>
      </div>
    </div>
    <div class="col-md-4">
      <span class="span-placeholder">Valor de la construcción</span>
      <div class="inputwrapper" data-required="MXM">
      <input class="vs-input" v-model="valor" name="username"/>
      </div>
    </div>
    <div class="col-md-4">
      <span class="span-placeholder">Montos de crédito</span>
      <div class="inputwrapper" data-required="MXM">
      <input class="vs-input" v-model="credito" name="name"/>
      </div>
    </div>
  
    <div class="col-md-4">
      <div>
        <span class="span-placeholder">Plazos a cotizar</span>
        <select class="vs-input" v-model="plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
      </div>
    </div>
    <div class="col-md-4">
      <div>
        <span class="span-placeholder">Esquemas de pago</span>
        <select class="vs-input" v-model="esquemasACotizar" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
      </div>
    </div>
    <!--<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
      <div>
        <span class="span-placeholder">Gastos notariales</span>
        <select name="gastosNotariales" id="gastosNotariales" v-model="gastosNotarial" class="v-select vs--single  vs-input--input" @change="updateValores()">
              <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
            </select>
      </div>
    </vs-col>-->
  </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
  data(){
		return{
      plazosACotizar:[{ label: '20 Años',  value: '20' }],
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '25 Años',  value: '25' },
        { label: '24 Años',  value: '24' },
        { label: '23 Años',  value: '23' },
        { label: '22 Años',  value: '22' },
        { label: '21 Años',  value: '21' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '15 Años',  value: '15' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '10 Años',  value: '10' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '5 Años',  value: '5' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
      valor:'',
      credito:'',
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      gastosNotarial:6
    }
  }
}
</script>

<style lang="css" scoped>
</style>
